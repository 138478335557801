import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// Hooks & Querys & Context
import { useSiteState } from '../lib/siteContext';
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

// Components
import Layout from '../components/layout';
import Seo from '../components/global/seo';
import BriefUs from '../components/global/briefUs';
import ModuleZone from '~moduleZone';

const PageTemplate = ({ data: { page } }) => {
  
  const { 
    pageModules, 
    briefUsCta, 
    myPorterCta, 
    pageTheme, 
    headerTheme, 
    footerForms, 
    footerTheme, 
    headerOverlaps, 
    seo 
  } = page || {}  

  const { 
    setPageFeatureColor, 
    setFooterHidden, 
    setPageBackground, 
    setMyPorter, 
    setHeaderDark, 
    setShowingBriefUs, 
    setFooterTheme, 
    setFooterForms 
  } = useSiteState();

  useEffect(() => {
    
    if (pageTheme.color.value) {
      document.documentElement.setAttribute('data-theme', `theme-${pageTheme.color.title}`);
      document.documentElement.style.setProperty(`--doc-bg`, pageTheme.color.value);      
    }              

    setHeaderDark(headerTheme && headerTheme.color.title === "Black" ? true : false)
    setFooterForms(footerForms === true ? true : false)
    setFooterTheme(footerTheme.color.title ? footerTheme.color.title : 'theme-none')          
    setMyPorter(myPorterCta === true ? true : false)
    setPageFeatureColor(null);
    setShowingBriefUs(briefUsCta)
    setFooterHidden(false)
    
  }, [
      pageTheme, 
      setFooterHidden, 
      setPageBackground, 
      setMyPorter, 
      myPorterCta, 
      headerTheme, 
      setHeaderDark, 
      setPageFeatureColor, 
      setShowingBriefUs, 
      briefUsCta, 
      footerTheme, 
      setFooterTheme, 
      footerForms, 
      setFooterForms
    ]
  );

  return (
    <Layout>     
       
       {seo && <Seo {...seo} />}

      {!headerOverlaps && <div className="block w-full h-20"></div>} 

       {pageModules && <ModuleZone {...pageModules} />}

       {briefUsCta && <BriefUs />}
       
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title            
      seo {
        ...SEO
      }
      myPorterCta
      footerForms
      briefUsCta
      headerTheme {
        color {
          value
          title
        }
      }
      headerOverlaps
      footerTheme {
        color {
          value
          title
        }
      }
      pageTheme {
        color {
          value
          title
        }
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`;
 