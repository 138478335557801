import { useStaticQuery, graphql } from 'gatsby';

export const useBriefUs = () => {
  const { sanityBriefUs } = useStaticQuery(graphql`
    query {
      sanityBriefUs {
        shortText
        buttonText
        buttonText
        form {
            title
            submitButton
              textSuccess: _rawTextSuccess(resolveReferences: {maxDepth: 10})
              text: _rawText(resolveReferences: {maxDepth: 10})
            inputs {
              items {
                ... on SanityFormsInput {
                  _key
                  _type
                  width
                  type
                  title
                  required
                  placeholder
                }
                ... on SanityFormsSelect {
                  _key
                  _type
                  width
                  values
                  title
                  required
                  placeholder
                }
                ... on SanityFormsTextarea {
                  _key
                  _type
                  width
                  title
                  required
                  placeholder
                }
              }
            }
          }
      }
    }
  `);
  return sanityBriefUs || {};
};
