import React from 'react'
import { motion, useViewportScroll, useTransform } from 'framer-motion'

// Hooks & Querys
import { useSiteState } from '../../lib/siteContext';
import { useBriefUs } from '../../GraphQl/useBriefUs';

const BriefUs = () => {

    const { setGlobalForm, setFormIsActive, formIsActive } = useSiteState();  

    const { scrollY } = useViewportScroll()
    const yRange = [0, 200, 600]
    const opacityRange = [0, 0, 1]
    const opacityValue = useTransform(scrollY, yRange, opacityRange)

    const { shortText, buttonText, form } = useBriefUs()

  return (
    <>
    <motion.div 
      className={`brief-us-cta text-sm fixed bottom-0 left-0 right-0 z-30 bg-gray-300 bg-opacity-30 backdrop-blur-lg px-gutter py-2 md:py-4 flex justify-between items-center}`}
      style={{ opacity: opacityValue, zIndex: opacityValue}}
    >
        <div>{shortText && shortText}</div>
        <button           
          className="form-trigger"          
          onClick={() => {
            setFormIsActive(!formIsActive)
            setGlobalForm(form)
          }}
          onKeyDown={() => {
            setFormIsActive(!formIsActive)
            setGlobalForm(form)
          }}
        >
            {buttonText && buttonText}
        </button>
    </motion.div>    
    </>
  )
}

export default BriefUs